
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ExpensesTable = () => import(/* webpackChunkName: "group-expenses-table" */ './components/ExpensesTable/ExpensesTable.vue')
const NewExpense = () => import(/* webpackChunkName: "group-expenses-create" */ './components/ExpenseCreation/ExpenseCreation.vue')
const ExpensesEditing = () => import(/* webpackChunkName: "group-expenses-edit" */ './components/ExpenseEditing/ExpenseEditing.vue')
const moduleKey = 'expenses'

export const ExpensesRoutes = [
  {
    path: routerPaths.expenses,
    component: ExpensesTable,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndExpenses',
          'expenses'
        ],
        items: {
          'incomeAndExpenses': {
            name: 'menu_incomeExpenses',
            link: routerPaths.expenses,
          },
          'expenses': {
            name: 'menu_expenses',
            link: routerPaths.expenses,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.EXPENSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_expenses'
    },
  },

  {
    path: routerPaths.incomeExpensesNewExpense,
    component: NewExpense,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndExpenses',
          'expenses',
          'incomeExpensesNewExpense'
        ],
        items: {
          'incomeAndExpenses': {
            name: 'menu_incomeExpenses',
            link: routerPaths.expenses,
          },
          'expenses': {
            name: 'menu_expenses',
            link: routerPaths.expenses,
          },
          'incomeExpensesNewExpense': {
            name: 'breadcrumbs_NewExpense',
            link: routerPaths.incomeExpensesNewExpense,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.EXPENSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewExpense'
    },
  },

  {
    path: routerPaths.incomeExpensesEditItem,
    component: ExpensesEditing,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndExpenses',
          'expenses',
          'incomeExpensesEdit'
        ],
        items: {
          'incomeAndExpenses': {
            name: 'menu_incomeExpenses',
            link: routerPaths.expenses,
          },
          'expenses': {
            name: 'menu_expenses',
            link: routerPaths.expenses,
          },
          'incomeExpensesEdit': {
            name: 'breadcrumbs_editExpense',
            link: routerPaths.incomeExpensesEditItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.EXPENSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_editExpense'
    },
  },
]
