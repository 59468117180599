import {API} from "../../axios/axiosMainUrl";

const prefix = '/warehouse/document/move-product'

export const productsMove = {
  state: {
    productsMove: [],
    productsMoveItem: {},
    productsMoveCommonList: [],
    productsMoveBtn: false,
    productsMoveLoading: true,
    nextProductsMovePage: false,
    productsMoveForPage: 25,
    productsMoveFilter: '',
  },

  mutations: {
    setProductsMoveFilter: (state, payload) => {
      state.productsMoveFilter = payload
    },

    setProductsMove: (state, payload) => {
      if(state.nextProductsMovePage) {
        state.productsMove = state.productsMove.concat(payload.productsMove)
      } else {
        state.productsMove = payload.productsMove
      }
    },

    setProductsMoveCommonList: (state, payload) => {
      state.productsMoveCommonList = payload.productsMoveCommonList
    },

    setNextProductsMovePage: (state, payload) => {
      state.nextProductsMovePage = payload
    },

    setProductsMoveItem: (state, payload) => {
      state.productsMoveItem = payload.productsMoveItem
    },

    setProductsMoveLoadingStart: (state) => {
      state.productsMoveLoading = true
    },

    setProductsMoveLoadingEnd: (state) => {
      state.productsMoveLoading = false
    },

    changeProductsMoveBtn: (state) => {
      state.productsMoveBtn = true
    },
    successProductsMoveBtn: (state) => {
      state.productsMoveBtn = false
    },
  },

  getters: {
    getProductsMoveFilter: state => {
      return state.productsMoveFilter
    },

    getProductsMoveForPage: state => {
      return state.productsMoveForPage
    },

    getProductsMove: state => {
      return state.productsMove
    },

    getProductsMoveLoading: state => {
      return state.productsMoveLoading
    },


    getNextProductsMovePage: state => {
      return state.nextProductsMovePage
    },

    getProductsMoveItem: state => {
      return state.productsMoveItem
    },

    getProductsMoveCommonList: state => {
      return state.productsMoveCommonList
    },

    getProductsMoveBtn: state => {
      return state.productsMoveBtn
    },
  },

  actions: {

    async fetchProductsMove({commit, getters}, filter = '') {
      if(!getters.getNextProductsMovePage)
        commit('setProductsMoveLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setProductsMove',{productsMove: response.data.data.data})
          commit('setProductsMoveCommonList',{productsMoveCommonList: response.data.data})
          commit('setProductsMoveLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsMoveLoadingEnd');
        return e;
      }
    },

    async getProductsMove({commit}, id) {
      // commit('setProductsMoveLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setProductsMoveItem', {productsMoveItem: response.data.data})
          commit('setProductsMoveLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsMoveLoadingEnd');
        return e;
      }
    },

    async createProductsMove({commit}, data) {
      commit('changeProductsMoveBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successProductsMoveBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsMoveBtn');
        return e;
      }
    },

    async updateProductsMove({commit}, {id, data}) {
      commit('changeProductsMoveBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successProductsMoveBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsMoveBtn');
        return e;
      }
    },

    async deleteProductsMove({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successProductsMoveBtn');
        return e;
      }
    },

  }
}
