import {API} from "../../axios/axiosMainUrl";

const prefix = '/express-orders/courier-report'

export const fedexUkraineReport = {
  state: {
    fedexUkraineReport: [],
    fedexUkraineReportItem: {},
    fedexUkraineReportCommonList: [],
    fedexUkraineReportBtn: false,
    fedexUkraineReportLoading: true,
    nextFedexUkraineReportPage: false,
    fedexUkraineReportForPage: 25,
    fedexUkraineReportFilter: '',
  },

  mutations: {
    setFedexUkraineReportFilter: (state, payload) => {
      state.fedexUkraineReportFilter = payload
    },

    setFedexUkraineReport: (state, payload) => {
      if(state.nextFedexUkraineReportPage) {
        state.fedexUkraineReport = state.fedexUkraineReport.concat(payload.fedexUkraineReport)
      } else {
        state.fedexUkraineReport = payload.fedexUkraineReport
      }
    },

    setFedexUkraineReportCommonList: (state, payload) => {
      state.fedexUkraineReportCommonList = payload.fedexUkraineReportCommonList
    },

    setNextFedexUkraineReportPage: (state, payload) => {
      state.nextFedexUkraineReportPage = payload
    },

    setFedexUkraineReportItem: (state, payload) => {
      state.fedexUkraineReportItem = payload.fedexUkraineReportItem
    },

    setFedexUkraineReportLoadingStart: (state) => {
      state.fedexUkraineReportLoading = true
    },

    setFedexUkraineReportLoadingEnd: (state) => {
      state.fedexUkraineReportLoading = false
    },

    changeFedexUkraineReportBtn: (state) => {
      state.fedexUkraineReportBtn = true
    },
    successFedexUkraineReportBtn: (state) => {
      state.fedexUkraineReportBtn = false
    },
  },

  getters: {
    getFedexUkraineReportFilter: state => {
      return state.fedexUkraineReportFilter
    },

    getFedexUkraineReportForPage: state => {
      return state.fedexUkraineReportForPage
    },

    getFedexUkraineReport: state => {
      return state.fedexUkraineReport
    },

    getFedexUkraineReportLoading: state => {
      return state.fedexUkraineReportLoading
    },


    getNextFedexUkraineReportPage: state => {
      return state.nextFedexUkraineReportPage
    },

    getFedexUkraineReportItem: state => {
      return state.fedexUkraineReportItem
    },

    getFedexUkraineReportCommonList: state => {
      return state.fedexUkraineReportCommonList
    },

    getFedexUkraineReportBtn: state => {
      return state.fedexUkraineReportBtn
    },
  },

  actions: {

    async fetchFedexUkraineReport({commit, getters}, filter = '') {
      if(!getters.getNextFedexUkraineReportPage)
        commit('setFedexUkraineReportLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setFedexUkraineReport',{fedexUkraineReport: response.data.data.data})
          commit('setFedexUkraineReportCommonList',{fedexUkraineReportCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setFedexUkraineReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFedexUkraineReportLoadingEnd');
        return e;
      }
    },

    async getFedexUkraineReport({commit}, id) {
      commit('setFedexUkraineReportLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setFedexUkraineReportItem', {fedexUkraineReportItem: response.data.data})
          commit('setFedexUkraineReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFedexUkraineReportLoadingEnd');
        return e;
      }
    },

    async createFedexUkraineReport({commit}, data) {
      commit('changeFedexUkraineReportBtn');
      try {
        return await API.get(`${prefix}/create/${data}`).then((response) =>{
          commit('successFedexUkraineReportBtn');
          return response;
        });
      } catch (e) {
        commit('successFedexUkraineReportBtn');
        return e;
      }
    },

    async updateFedexUkraineReport({commit}, {id, data}) {
      commit('changeFedexUkraineReportBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successFedexUkraineReportBtn');
          return response;
        });
      } catch (e) {
        commit('successFedexUkraineReportBtn');
        return e;
      }
    },

    async deleteFedexUkraineReport({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successFedexUkraineReportBtn');
        return e;
      }
    },

    async deleteFedexUkraineReportElement({commit}, id) {
      try {
        return await API.delete(`${prefix}/element/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successFedexUkraineReportBtn');
        return e;
      }
    },

    async exportFedexUkraineReportElement({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/generate-document`, { responseType: 'blob' }).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successFedexUkraineReportBtn');
        return e;
      }
    },

    async checkElementsFedexUkraineReport({commit}, {id, data}) {
      commit('changeFedexUkraineReportBtn');
      try {
        return await API.post(`${prefix}/${id}/elements-check`, data).then((response) =>{
          commit('successFedexUkraineReportBtn');
          return response;
        });
      } catch (e) {
        commit('successFedexUkraineReportBtn');
        return e;
      }
    },

  }
}
