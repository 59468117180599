import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const FedexUkraineReportTable = () => import(/* webpackChunkName: "group-fedex-ukraine-report" */ './components/FedexUkraineReportTable/FedexUkraineReportTable.vue')
const FedexUkraineReportEdit = () => import(/* webpackChunkName: "group-fedex-ukraine-report-edit" */ './components/FedexUkraineReportEdit/FedexUkraineReportEdit.vue')

const moduleKey = 'fedex-ukraine-report'

export const FedexUkraineReportRoutes = [
  {
    path: routerPaths.fedexUkraineReport,
    component: FedexUkraineReportTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_reports',
          'mainSettingsFedexUkraineReport'
        ],
        items: {
          'menu_reports': {
            name: 'menu_reports',
            link: routerPaths.fedexUkraineReport,
          },
          'mainSettingsFedexUkraineReport': {
            name: 'menu_FedexUkraineReport',
            link: routerPaths.fedexUkraineReport,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.OE_COURIER_REPORT],

      moduleKey: moduleKey,
      pageTitle: 'menu_FedexUkraineReport'
    },
  },
  {
    path: routerPaths.fedexUkraineReportShow,
    component: FedexUkraineReportEdit,
    meta: {
      breadcrumbs: {
        key: [
          'menu_reports',
          'menu_FedexUkraineReport'
        ],
        items: {
          'menu_reports': {
            name: 'menu_reports',
            link: routerPaths.fedexUkraineReportShow,
          },
          'menu_FedexUkraineReport': {
            name: 'menu_fedexUkraineReportShow',
            link: routerPaths.fedexUkraineReportShow,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.OE_COURIER_REPORT],

      moduleKey: moduleKey,
      pageTitle: 'menu_fedexUkraineReportShow'
    },
  }
]
