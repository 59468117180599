import {RETURN_GOODS_TYPES} from "@/staticData/staticVariables";

export const routerAdminPaths = {
  isAdminRoutes: 'admin',
  auth: '/auth',
  login: '/auth/login',
  forgot: '/auth/forgot',
  forgotReset: '/auth/forgot-reset',

  getFile: '/file',
  getPdfFile: '/file/pdf',
  getPdfFileId: '/file/pdf/:id',
  generatePdfFile: '/generate/pdf',
  generatePdfFileId: '/generate/pdf/:type',

  authAdmin: '/admin/auth',
  loginAdmin: '/admin/auth/login',
  forgotAdmin: '/admin/auth/forgot',
  forgotResetAdmin: '/admin/auth/forgot-reset',

  signUp: '/registration',

  authentication: '/admin/profile',
  authenticationGoogle: '/admin/profile/google',
  authenticationEmail: '/admin/profile/email',
  authenticationGoogleType: '/admin/profile/google/:linkName',
  authenticationEmailType: '/admin/profile/email/:linkName',

  transactions: '/admin/transactions',
  cusPayments: '/admin/transactions/customer-payments',
  cusPaymentsEdit: '/admin/transactions/customer-payments/edit',
  financeMyPayments: '/admin/finance/my-payments',

  financeUnidentifiedPayments: '/admin/finance/unidentified-payments',

  financeUkrTreasuresLink: '/admin/finance/ukrainian-treasures',
  financeUkrTreasuresLinkCreate: '/admin/finance/ukrainian-treasures/create',
  financeUkrTreasuresLinkEdit: '/admin/finance/ukrainian-treasures/:id',

  financeUkrTreasuresPayPalButtonCreate: '/admin/finance/create-paypal-button',

  financeRequestReturn: '/admin/finance/request-return',
  financeRequestReturnEdit: '/admin/finance/request-return/edit',
  financeRequestReturnEditId: '/admin/finance/request-return/edit/:id',
  financeRequestReturnCreate: '/admin/finance/request-return/create',
  financeRequestReturnCreateId: '/admin/finance/request-return/create/:id',

  financePayPalCases: '/admin/finance/paypal-cases',
  financePayPalCasesCreate: '/admin/finance/paypal-cases/create',
  financePayPalCasesEdit: '/admin/finance/paypal-cases/:id',
  financePayPalCasesShow: '/admin/finance/paypal-cases/show/:id',

  financeInvoices: '/admin/finance/invoices',
  financeInvoicesCreate: '/admin/finance/invoices/create',
  financeInvoicesEdit: '/admin/finance/invoices/edit/:id',

  financeRequestFunds: '/admin/finance/request-funds',
  financeRequestFundsIndividual: '/admin/finance/request-funds/individual',
  financeRequestFundsBusiness: '/admin/finance/request-funds/business',
  financeRequestFundsNoTr: '/admin/finance/request-funds/no-transaction',
  financeRequestFundsIndividualEdit: '/admin/finance/request-funds/individual/:id',
  financeRequestFundsBusinessEdit: '/admin/finance/request-funds/business/:id',
  financeRequestFundsNoTrEdit: '/admin/finance/request-funds/no-transaction/:id',

  financeNewPayment: '/admin/finance/new-payment',
  financeNewPaymentCreate: '/admin/finance/new-payment/create',
  financeNewPaymentEdit: '/admin/finance/new-payment/edit/:id',

  financeNewPaymentsPayoneer: '/admin/finance/new-payoneer-payment',
  financeNewPaymentsPayoneerCreate: '/admin/finance/new-payoneer-payment/create',
  financeNewPaymentsPayoneerEdit: '/admin/finance/new-payoneer-payment/edit/:id',

  financeReportCells: '/admin/reports/report-cells',
  financeReportCellsItem: '/admin/reports/report-cells/:cellId/:productId',

  scanFormDP: '/admin/reports/scan-form-dp',

  fedexUkraineReport: '/admin/reports/fedex-ukraine-report',
  fedexUkraineReportShow: '/admin/reports/fedex-ukraine-report/:id',

  forbiddenGoods: '/admin/finance/forbidden-goods',


  forbiddenKeywords: '/admin/settings/forbidden-keywords',

  shippingInsurances: '/admin/settings/shipping-insurances',

  consolidationUsers: '/admin/settings/consolidation-users',

  trackTest: '/admin/settings/track-test',

  problemsFillClaim: '/admin/problems/fill-claim',
  problemsFillClaimCreate: '/admin/problems/fill-claim/create',
  problemsFillClaimEdit: '/admin/problems/fill-claim/:id',
  problemsFillClaimShow: '/admin/problems/fill-claim/show/:id',

  problemsParcels: '/admin/problems/parcels',


  problemsReturnsModule: '/admin/problems',
  problemsMyReturns: '/admin/problems/my-returns',
  problemsUnidentifiedReturns: '/admin/problems/unidentified-returns',
  problemsProcessedReturnsGoods: '/admin/problems/processed-package-returns',
  problemsProcessedReturnsGoodsType: '/admin/problems/processed-package-returns/:type',
  problemsProcessedReturnsGoodsMyReturns: '/admin/problems/processed-package-returns/' + RETURN_GOODS_TYPES.MY_RETURNS,
  problemsProcessedReturnsGoodsUnprocessed: '/admin/problems/processed-package-returns/' + RETURN_GOODS_TYPES.UNPROCESSED_RETURNS,
  problemsProcessedReturnsGoodsProcessed: '/admin/problems/processed-package-returns/' + RETURN_GOODS_TYPES.PROCESSED_RETURNS,
  problemsProcessedReturnsGoodsTrash: '/admin/problems/processed-package-returns/' + RETURN_GOODS_TYPES.TRASH,
  problemsRequestReturnsGoods: '/admin/problems/request-return-package',
  problemsReturnGoodsUrl: '/admin/problems/return-package',
  problemsReturnGoodsCreation: '/admin/problems/return-package/create',
  problemsReturnGoodsEditing: '/admin/problems/return-package/:id',
  problemsReturnGoodsShow: '/admin/problems/return-package/show/:id',

  // problemsMeestReturns: '/admin/problems/return-meest',
  // problemsMeestReturnsEdit: '/admin/problems/return-meest/:id',
  // problemsMeestReturnsShow: '/admin/problems/return-meest/show/:id',

  ordersAndDispatches: '/admin/orders-and-dispatches',


  incomeExpenses: '/admin/income-and-expenses/',
  incomeExpensesProfitReport: '/admin/income-and-expenses/profit-report',
  incomeExpensesShippingReport: '/admin/income-and-expenses/shipping-report',

  expenses: '/admin/income-and-expenses/expenses',
  incomeExpensesNewExpense: '/admin/income-and-expenses/expenses/create',
  incomeExpensesEdit: '/admin/income-and-expenses/expenses/edit',
  incomeExpensesEditItem: '/admin/income-and-expenses/expenses/edit/:id',

  otherIncome: '/admin/income-and-expenses/other-income',
  incomeOtherIncomeCreate: '/admin/income-and-expenses/other-income/create',
  incomeOtherIncomeEdit: '/admin/income-and-expenses/other-income/edit',
  incomeOtherIncomeEditItem: '/admin/income-and-expenses/other-income/edit/:id',


  expressOrders: '/admin/orders-and-dispatches/express',
  expressOrderCreation: '/admin/orders-and-dispatches/express/create/',
  expressOrderCreationID: '/admin/orders-and-dispatches/express/create/:type',
  expressOrderCreationIDTransaction: '/admin/orders-and-dispatches/express/create/:type/:typeTransaction/:id',
  expressOrderEdit: '/admin/orders-and-dispatches/express/edit',
  expressOrderEditItem: '/admin/orders-and-dispatches/express/edit/:type/:id',
  expressOrderShow: '/admin/orders-and-dispatches/express/show',
  expressOrderShowItem: '/admin/orders-and-dispatches/express/show/:type/:id',
  expressLabels: '/admin/orders-and-dispatches/express/labels',


  easyOrders: '/admin/orders-and-dispatches/easy',
  easyOrderCreation: '/admin/orders-and-dispatches/easy/create/',
  easyOrderCreationID: '/admin/orders-and-dispatches/easy/create/:type',
  easyOrderCreationIDTransaction: '/admin/orders-and-dispatches/easy/create/:type/:typeTransaction/:id',
  easyOrderEdit: '/admin/orders-and-dispatches/easy/edit',
  easyOrderEditItem: '/admin/orders-and-dispatches/easy/edit/:type/:id',
  easyOrderShow: '/admin/orders-and-dispatches/easy/show',
  easyOrderShowItem: '/admin/orders-and-dispatches/easy/show/:type/:id',
  easyLabels: '/admin/orders-and-dispatches/easy/labels',


  ordersMeestReturns: '/admin/orders-and-dispatches/meest',
  ordersMeestReturnsCreation: '/admin/orders-and-dispatches/meest/create',
  ordersMeestReturnsEdit: '/admin/orders-and-dispatches/meest/:id',
  ordersMeestReturnsShow: '/admin/orders-and-dispatches/meest/show/:id',


  ordersFBM: '/admin/orders-and-dispatches/orders',
  ordersFBMManifests: '/admin/manifests',
  ordersFBMCreate: '/admin/orders-and-dispatches/orders/create',
  ordersFBMCreation: '/admin/orders-and-dispatches/orders/create/:type',
  ordersFBMCreationTransactionId: '/admin/orders-and-dispatches/orders/create/:type/:typeTransaction/:id',
  ordersFBMItem: '/admin/orders-and-dispatches/orders/show/:type/:id',
  // ordersFBMCreationConsolidation: '/admin/orders-and-dispatches/orders/create/consolidation',
  // ordersFBMCreationWarehouse: '/admin/orders-and-dispatches/orders/create/warehouse',
  // ordersFBMCreationLabel: '/admin/orders-and-dispatches/orders/create/label',
  // ordersFBMCreationEasy: '/admin/orders-and-dispatches/orders/create/easy',

  ordersFBMEditing: '/admin/orders-and-dispatches/orders/:type/:id',
  ordersFBMShow: '/admin/orders-and-dispatches/orders/show/:type/:id',
  ordersFBMEditingConsolidation: '/admin/orders-and-dispatches/orders/edit/consolidation/',
  ordersFBMEditingWarehouse: '/admin/orders-and-dispatches/orders/edit/warehouse/',
  // ordersFBMEditingLabel: '/admin/orders-and-dispatches/orders/edit/label/:id',
  // ordersFBMEditingEasy: '/admin/orders-and-dispatches/orders/edit/easy/:id',

  ordersFBA: '/admin/orders-and-dispatches/fba-orders',
  ordersFBACreation: '/admin/orders-and-dispatches/fba-orders/create/',
  ordersFBAEditing: '/admin/orders-and-dispatches/fba-orders/:id',
  ordersFBAShow: '/admin/orders-and-dispatches/fba-orders/show/:id',

  ordersShipmentWarehouse: '/admin/orders-and-dispatches/shipment',
  ordersShipmentWarehouseCreate: '/admin/orders-and-dispatches/shipment/create/',
  ordersShipmentWarehouseCreation: '/admin/orders-and-dispatches/shipment/create/:type',
  ordersShipmentWarehouseEditing: '/admin/orders-and-dispatches/shipment/:type/:id',
  ordersShipmentWarehouseDetail: '/admin/orders-and-dispatches/shipment/detail/',
  ordersShipmentWarehouseDetailInfo: '/admin/orders-and-dispatches/shipment/detail/:type/:id',

  ordersConsolidation: '/admin/orders-and-dispatches/consolidation',
  ordersConsolidationCreate: '/admin/orders-and-dispatches/consolidation/create',
  ordersConsolidationCreation: '/admin/orders-and-dispatches/consolidation/create/:type',
  ordersConsolidationDetail: '/admin/orders-and-dispatches/consolidation/detail',
  ordersConsolidationDetailInfo: '/admin/orders-and-dispatches/consolidation/detail/:type/:id',
  ordersConsolidationEditing: '/admin/orders-and-dispatches/consolidation/edit',
  ordersConsolidationEditingItem: '/admin/orders-and-dispatches/consolidation/edit/:type/:id',

  ukrposhta: '/admin/orders-and-dispatches/ukrposhta',
  ukrposhtaCreation: '/admin/orders-and-dispatches/ukrposhta/create',
  ukrposhtaCreationPayments: '/admin/orders-and-dispatches/ukrposhta/create/:typeTransaction/:id',
  ukrposhtaEditing: '/admin/orders-and-dispatches/ukrposhta/:id',
  ukrposhtaShow: '/admin/orders-and-dispatches/ukrposhta/show/:id',

  belposhta: '/admin/orders-and-dispatches/belposhta',
  belposhtaCreation: '/admin/orders-and-dispatches/belposhta/create',
  belposhtaCreationPayments: '/admin/orders-and-dispatches/belposhta/create/:typeTransaction/:id',
  belposhtaEditing: '/admin/orders-and-dispatches/belposhta/:id',
  belposhtaShow: '/admin/orders-and-dispatches/belposhta/show/:id',

  cdek: '/admin/orders-and-dispatches/cdek',
  cdekCreation: '/admin/orders-and-dispatches/cdek/create',
  cdekCreationFromOrder: '/admin/orders-and-dispatches/cdek/create/:type/:id',
  cdekEditing: '/admin/orders-and-dispatches/cdek/:id',
  cdekShow: '/admin/orders-and-dispatches/cdek/show/:id',

  novaPoshta: '/admin/orders-and-dispatches/nova-poshta',
  novaPoshtaCreation: '/admin/orders-and-dispatches/nova-poshta/create',
  novaPoshtaCreationFromOrder: '/admin/orders-and-dispatches/nova-poshta/create/:type/:id',
  novaPoshtaEditing: '/admin/orders-and-dispatches/nova-poshta/:id',
  novaPoshtaShow: '/admin/orders-and-dispatches/nova-poshta/show/:id',

  importOrders: '/admin/orders-and-dispatches/import-orders',

  warehouseDisposalOrders: '/admin/warehouse/disposal-orders',
  warehouseDisposalOrdersCreation: '/admin/warehouse/disposal-orders/create',
  warehouseDisposalOrdersEdit: '/admin/warehouse/disposal-orders/:id',

  products: '/admin/warehouse/products',
  productsCreate: '/admin/warehouse/products-create',
  productsItem: '/admin/warehouse/products/show/:id',
  productsEdit: '/admin/warehouse/products/:id',
  productsImport: '/admin/warehouse/products/import',

  refillProducts: '/admin/warehouse/refill-products',


  marketplaceProducts: '/admin/marketplace/marketplace-products',
  marketplaceProductsCreate: '/admin/marketplace/marketplace-products/create',
  marketplaceProductsItem: '/admin/marketplace/marketplace-products/show/:id',
  marketplaceProductsEdit: '/admin/marketplace/marketplace-products/:shopId/:id',

  marketplaceRates: '/admin/marketplace/marketplace-rates',

  marketplaceReviews: '/admin/marketplace/marketplace-reviews',
  marketplaceReviewsCreate: '/admin/marketplace/marketplace-reviews/create',
  marketplaceReviewsItem: '/admin/marketplace/marketplace-reviews/show/:id',
  marketplaceReviewsEdit: '/admin/marketplace/marketplace-reviews/:id',

  marketplaceSettingsEditing: '/admin/shops/marketplace-settings',
  marketplaceSettingsEditItem: '/admin/shops/marketplace-settings/:id',


  dashboard: '/admin/dashboard',
  dashboardUser: '/dashboard',
  dashboardAdmin: '/admin/dashboard',


  calculator: '/admin/shipping-calculator',

  calculatorEngraving: '/admin/shipping-engraving-calculator',

  statisticsEtsyRank: '/admin/statistics/etsy-rank',

  statisticsDeliveryStatistics: '/admin/statistics/delivery-statistics',

  notificationsList: '/admin/list-notification',

  graphicsStatistic: '/admin/statistics/graphics-statistic',

  reportsConsolidationMeest: '/admin/reports/consolidation-meest',
  reportsConsolidationMeestCreation: '/admin/reports/consolidation-meest/create',
  reportsConsolidationMeestEdit: '/admin/reports/consolidation-meest/:id',
  reportsConsolidationMeestShow: '/admin/reports/consolidation-meest/show/:id',
  reportsConsolidationMeestSent: '/admin/reports/consolidation-sent-meest',
  reportsConsolidationMeestSentShow: '/admin/reports/consolidation-sent-meest/show/:id',

  reportsAPCDelcon: '/admin/reports/apc-delcon',
  reportsAPCDelconCreate: '/admin/reports/apc-delcon/create',
  reportsAPCDelconEdit: '/admin/reports/apc-delcon/edit',
  reportsAPCDelconEditItem: '/admin/reports/apc-delcon/edit/:id',

  reportsConsolidation: '/admin/reports/consolidation-union',
  reportsConsolidationCreate: '/admin/reports/consolidation-union/create',
  reportsConsolidationEdit: '/admin/reports/consolidation-union/edit',
  reportsConsolidationEditItem: '/admin/reports/consolidation-union/edit/:id',
  reportsConsolidationCreateLabel: '/admin/reports/consolidation-union/label',
  reportsConsolidationCreateLabelItem: '/admin/reports/consolidation-union/label/:id',
  reportsConsolidationEditLabel: '/admin/reports/consolidation-union/edit-label',
  reportsConsolidationEditLabelItem: '/admin/reports/consolidation-union/edit-label/:id',

  warehouseProductsReport: '/admin/reports/products-report',

  productsMove: '/admin/reports/products-move-report',

  reportsProfitReport: '/admin/reports/profit-reports/',
  reportsProfitReportTotal: '/admin/reports/profit-reports/total',
  reportsProfitReportTransactions: '/admin/reports/profit-reports/transaction',

  reportsGenerateReportPage: '/admin/reports/generate-report-page',
  reportsGenerateReportCurOrdersPage: '/admin/reports/generate-report-page/current-orders',

  reportsGenerateReportFBAPage: '/admin/reports/generate-report-fba-page',

  reportsProcessFBMReport: '/admin/reports/process-fbm-report',
  trackingForPayPal: '/admin/reports/tracking-paypal',

  reportsConsolidationOrders: '/admin/reports/orders-in-consolidation',

  reportsConnectionsHistory: '/admin/reports/connection-history',

  expressPoshtaReport: '/admin/reports/express-poshta',

  IE559Report: '/admin/finance/ie-599',

  reportsFastOrders: '/admin/reports/fast-orders',
  reportsFastOrdersCreate: '/admin/reports/fast-orders/create',
  reportsFastOrdersEdit: '/admin/reports/fast-orders/edit',
  reportsFastOrdersEditItem: '/admin/reports/fast-orders/edit/:id',

  reportsExpressFastOrders: '/admin/reports/express-fast-orders',
  reportsExpressFastOrdersCreate: '/admin/reports/express-fast-orders/create',
  reportsExpressFastOrdersEdit: '/admin/reports/express-fast-orders/edit',
  reportsExpressFastOrdersEditItem: '/admin/reports/express-fast-orders/edit/:id',

  reportsExpressProblem: '/admin/reports/express-problem',

  transactionReports: '/admin/reports/transaction-report',

  shippingReport: '/admin/reports/shipping-report',

  transactionExport: '/admin/report/export',

  inactiveFBMUsers: '/admin/reports/inactive-fbm-users',

  settings: '/admin/system',
  settingsUsers: '/admin/system/users',
  settingsUsersItem: '/admin/system/users/:id',

  settingsRoles: '/admin/system/roles',
  settingsRolesCreate: '/admin/system/roles-create',
  settingsRolesItem: '/admin/system/roles/:id',

  settingsPermissions: '/admin/system/permissions',
  settingsPermissionsCreate: '/admin/system/permissions-create',
  settingsPermissionsItem: '/admin/system/permissions/:id',
  settingsPermissionsGroup: '/admin/system/group-permissions',
  settingsPermissionsGroupCreate: '/admin/system/group-permissions-create',
  settingsPermissionsGroupItem: '/admin/system/group-permissions/:id',

  settingsTranslations: '/admin/settings/translations',
  settingsTranslationsCreate: '/admin/settings/translations-create',
  settingsTranslationsItem: '/admin/settings/translations/:id',
  settingsTranslationsGroup: '/admin/system/group-translations',
  settingsTranslationsGroupCreate: '/admin/system/group-translations-create',
  settingsTranslationsGroupItem: '/admin/system/group-translations/:id',

  settingsVueComponents: '/admin/system/vue-components',
  settingsVueComponentsCreate: '/admin/system/vue-components-create',
  settingsVueComponentsItem: '/admin/system/vue-components/:id',

  settingsAdminAuth: '/admin/settings/admin-auth',

  mainSettings: '/admin/settings',

  mainSettingsEdit: '/admin/settings-edit',

  mainSettingsMyProfile: '/admin/settings/profile',

  mainSettingsCustomsInformation: '/admin/settings/customs-information',

  mainSettingsNotifications: '/admin/settings/notifications',

  mainSettingsShops: '/admin/settings/shops',
  mainSettingsShopsCreate: '/admin/settings/shops/create',
  mainSettingsShopsEdit: '/admin/settings/shops/:id',

  downloadPlugins: '/admin/settings/modules',

  mainSettingsWarehouseManagement: '/admin/settings/warehouses-management',
  mainSettingsWarehouseManagementCreate: '/admin/settings/warehouses-management/create',
  mainSettingsWarehouseManagementEdit: '/admin/settings/warehouses-management/:id',

  mainSettingsManageUsersLink: '/admin/settings/subusers',
  mainSettingsManageUsersLinkCreate: '/admin/settings/subusers/create',
  mainSettingsManageUsersLinkEdit: '/admin/settings/subusers/:id',

  mainSettingsManageUsersGroupLink: '/admin/settings/subusers/group',
  mainSettingsManageUsersGroupLinkCreate: '/admin/settings/subusers/group/create',
  mainSettingsManageUsersGroupLinkEdit: '/admin/settings/subusers/group/:id',


  balanceHistory: '/admin/balance-history',

  balancePayoneerHistory: '/admin/balance-history-payoneer',

  mainSettingsUsers: '/admin/users',
  mainSettingsUsersCountCorrect: '/admin/users/count-correct',
  mainSettingsUsersEdit: '/admin/users/:id',
  mainSettingsUsersShow: '/admin/users/show/:id',
  mainSettingsUsersSubAdminCreate: '/admin/users/sub-admin-create',

  mainSettingsFreshChat: '/admin/settings/fresh-chat',

  mainSettingsEtsyListings: '/admin/settings/etsy-listings',
  mainSettingsEtsyListingsCreate: '/admin/settings/etsy-listings/create',
  mainSettingsEtsyListingsCreateListing: '/admin/settings/etsy-listings/:shopId/create',
  mainSettingsEtsyListingsEdit: '/admin/settings/etsy-listings/new',
  mainSettingsEtsyListingsEditListing: '/admin/settings/etsy-listings/:shopId/:listingId',
  mainSettingsEtsyListingsShop: '/admin/settings/etsy-listings/:shopId',


  mainSettingsEtsyTransfer: '/admin/settings/etsy-transfer',
  mainSettingsEtsyTransferShop: '/admin/settings/etsy-transfer/shop/:id',


  mainSettingsProfilesLink: '/admin/settings/user-templates',

  mainSettingsProformCategory: '/admin/settings/proform-category',
  mainSettingsProformData: '/admin/settings/proform-data',

  mainSettingsHSCodes: '/admin/settings/hs-codes',

  mainSettingsOtherShippers: '/admin/settings/other-shippers',

  mainSettingsOrderPrice: '/admin/settings/order-price',
  mainSettingsZonePrice: '/admin/settings/zone-price',
  mainSettingsOrderSize: '/admin/settings/order-size',
  mainSettingsPromoCode: '/admin/settings/promo-code',

  mainSettingsEasypostInvoices: '/admin/settings/easypost-invoices',

  mainSettingsShippingCost: '/admin/settings/shipping-cost',

  mainSettingsPackagingTypes: '/admin/settings/packaging-types',
  mainSettingsPackagingTypesCreate: '/admin/settings/packaging-types/create',
  mainSettingsPackagingTypesEdit: '/admin/settings/packaging-types/:id',

  mainSettingsCustomizationOfferCreate: '/admin/settings/customization-offer/create',
  mainSettingsCustomizationOfferCreateType: '/admin/settings/customization-offer/create/:type',
  mainSettingsCustomizationOfferEdit: '/admin/settings/customization-offer',
  mainSettingsCustomizationOfferEditType: '/admin/settings/customization-offer/:type/:id',

  mainSettingsCells: '/admin/settings/cells',

  settingsOrderBin: '/admin/settings/order-bin',

  mainSettingsInventory: '/admin/settings/inventory',
  mainSettingsInventoryShow: '/admin/settings/inventory/show/:id',
  mainSettingsInventoryEdit: '/admin/settings/inventory/edit/:id',

  mainSettingsIOSSNumbers: '/admin/settings/ioss-numbers',
  mainSettingsIOSSNumbersCreate: '/admin/settings/ioss-numbers/create',
  mainSettingsIOSSNumbersEdit: '/admin/settings/ioss-numbers/edit/:id',
  mainSettingsIOSSNumbersShow: '/admin/settings/ioss-numbers/show/:id',

  mainSettingsInsuranceSkladUsa: '/admin/settings/insurance-skladusa',
  mainSettingsInsuranceSkladUsaCreate: '/admin/settings/insurance-skladusa/create',
  mainSettingsInsuranceSkladUsaEdit: '/admin/settings/insurance-skladusa/edit/:id',
  mainSettingsInsuranceSkladUsaShow: '/admin/settings/insurance-skladusa/show/:id',

  mainSettingsCalculatorSettings: '/admin/settings/calculator-settings',

  thermalPrint: '/admin/settings/thermal-print',
  authTokens: '/admin/settings/static-tokens',
  scanBarcodeFBM: '/admin/settings/scan-fbm-label',


  shippingCompany: '/admin/settings/shipping-company',


  accountTypes: '/admin/settings/account-banks',
  accountTypesCreate: '/admin/settings/account-banks/create',
  accountTypesEditing: '/admin/settings/account-banks/:id',


  accounts: '/admin/settings/accounts',
  accountsCreate: '/admin/settings/accounts/create/',
  accountsCreation: '/admin/settings/accounts/create/:type',
  accountsEditing: '/admin/settings/accounts/:type/:id',

  multiSearch: '/admin/multi-search',
  multiSearchQuery: '/admin/multi-search/:query',

  search: '/admin/search',
  searchTrack: '/admin/search/tracking-number',

  scanBarcode: '/admin/scan-barcode',

  synchronizeShop: '/synchronize/shop',

  shopifyConnection: '/shopify/app',

  shopifyInfoPage: '/shopify/connection/info',

  historyOfChanges: '/admin/history',

  chat: '/admin/sms-chat',

  heartysan: '/admin/heartysan',
  heartysanChoice: '/admin/heartysan/choice',

  heartysanManufacturerListings: '/admin/heartysan/manufacturer-products',
  heartysanManufacturerListingsShow: '/admin/heartysan/manufacturer-products/:id',

  heartysanSellerListings: '/admin/heartysan/seller-listings',
  heartysanSellerListingsShow: '/admin/heartysan/seller-listings/:id',

  heartysanListings: '/admin/heartysan/listings',
  heartysanListingsCreate: '/admin/heartysan/listings/create',
  heartysanListingsEdit: '/admin/heartysan/listings/edit/:id',

  heartysanChat: '/admin/heartysan/chat',

  heartysanCategory: '/admin/heartysan/category',
  heartysanCategoryCreate: '/admin/heartysan/category/create',
  heartysanCategoryEdit: '/admin/heartysan/category/edit/:id',
  heartysanCategoryShow: '/admin/heartysan/category/:id',

  heartysanUsers: '/admin/heartysan/users',


  heartysanEtsyListings: '/admin/heartysan/etsy-listings',
  heartysanEtsyListingsCreate: '/admin/heartysan/etsy-listings/create',
  heartysanEtsyListingsCreateListing: '/admin/heartysan/etsy-listings/:shopId/create',
  heartysanEtsyListingsEdit: '/admin/heartysan/etsy-listings/new',
  heartysanEtsyListingsEditListing: '/admin/heartysan/etsy-listings/:shopId/:listingId',
  heartysanEtsyListingsShop: '/admin/heartysan/etsy-listings/:shopId',

  exportRequests: '/admin/exports-list',

  errorLogs: '/admin/system/error-logs',
  errorLogsShow: '/admin/system/error-logs/show/:id',

  errorLogsBackend: '/admin/system/backend-error-logs',
  errorLogsBackendShow: '/admin/system/backend-error-logs/show/:id',

  negativeBalance: '/admin/finance/negative-balance',

  errorPage503: '/admin/503',
  errorPage504: '/admin/504',
  errorPage404: '/admin/*',
  notFound: '/admin/404',
}
