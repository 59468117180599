<template>
  <div></div>
</template>

<script>
import {API} from "../../../axios/axiosMainUrl";

export default {
  name: "SiteTitleManager",

  watch: {
    '$route.meta.pageTitle' () {
      this.initTitle()
    },

    getLocale () {
      this.initTitle()
    },
  },

  computed: {
    getLocale() {
      return this.$root.$i18n.locale
    }
  },

  mounted() {
    this.initTitle()
  },

  methods: {
    initTitle() {
      let baseApiUrl = API.defaults.baseURL
      console.log(baseApiUrl);
      if(baseApiUrl.indexOf('api.skladusa.dev') > -1) return document.title = 'Dev SkladUsa.dev'

      if(this.$t(`${this.$route.meta?.pageTitle}.localization_value.value`).indexOf('undefined') > -1 ||
          this.$t(`${this.$route.meta?.pageTitle}.localization_value.value`).indexOf('localization_value') > -1
      ){
        setTimeout(() => {
          if(this.$t(`${this.$route.meta?.pageTitle}.localization_value.value`).indexOf('localization_value') > -1){
            document.title = 'SkladUsa.com'
            return
          }
          document.title = this.$t(`${this.$route.meta?.pageTitle}.localization_value.value`) + ' | SkladUsa.com'
        }, 1000)
      } else {
        document.title = this.$t(`${this.$route.meta?.pageTitle}.localization_value.value`) + ' | SkladUsa.com'
      }

    },
  }

}
</script>

<style scoped>

</style>