import Echo from "laravel-echo";
window.io = require('socket.io-client');

export const INIT_SOCKET_ECHO = () => {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: 'https://api.skladusa.dev', // this is laravel-echo-server host
    auth : {
      headers : {
        Authorization : `Bearer ${localStorage.getItem('token')}`
      }
    },
    csrfToken: localStorage.getItem('csrf'),
  });
}

export const SOCKET_MODE = 'ON'