
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const CalculatorSettingsTable = () => import(/* webpackChunkName: "group-calculator-settings" */ './components/CalculatorSettingsTable/CalculatorSettingsTable.vue')
const moduleKey = 'consolidation-users'

export const CalculatorSettingsRoutes = [
  {
    path: routerPaths.mainSettingsCalculatorSettings,
    component: CalculatorSettingsTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsCalculatorSettings'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsCalculatorSettings,
          },
          'mainSettingsCalculatorSettings': {
            name: 'menu_calculatorSettings',
            link: routerPaths.mainSettingsCalculatorSettings,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CALCULATOR_SETTING_PERMISSION],

      moduleKey: moduleKey,
      pageTitle: 'menu_calculatorSettings'
    },
  }
]
