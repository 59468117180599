import axios from 'axios'
import {router} from '../router/router'
import {routerPaths} from "../router/routerPaths";
import {EN_LANG} from '../staticData/staticVariables'
import {store} from './../store/store'
import {ErrorLog} from "@/components/globalModels/ErrorLog";

export const API = axios.create({
  baseURL: 'https://api.skladusa.dev/rest/v1/'
})

/**** SET X-CSRF-TOKEN *****/
if(localStorage.getItem('csrf')){
  API.defaults.headers.common['X-CSRF-TOKEN'] = localStorage.getItem('csrf');
}


/**** SET TOKEN *****/
if(localStorage.getItem('token')){
  API.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}

let lang = EN_LANG;

/**** SET Language ****/
if(localStorage.getItem('translationLang')){
  lang = localStorage.getItem('translationLang');
}



/**** SET DEFAULT HEADERS *****/
API.defaults.withCredentials = true;
API.defaults.headers.post['Content-Type'] ='application/json';
API.defaults.headers.post['Content-Language'] = lang;
API.defaults.headers.common['Accept-Language'] = lang;

API.defaults.headers.post['Access-Control-Allow-Origin'] = 'https://api.skladusa.dev';


/**** ERROR DUMPERS *****/
API.interceptors.response.use(function (response) {

  return response;
}, function (error) {

  if (error.response) {

    if(error.response.status === store.getters.GET_ERRORS.UNAUTHORIZED_CODE){

      // /**
      //  * Loging UNAUTHORIZED_CODE
      //  */
      // let ErrorLogModel = new ErrorLog()
      // ErrorLogModel.setItem({
      //   baseURI: router.app?.$el.baseURI,
      //   vueComponent: 'UNAUTHORIZED',
      //   user: `Id: ${store.getters.GET_COMMON_AUTHORIZED?.user?.id}, email: ${store.getters.GET_COMMON_AUTHORIZED?.user?.email}`,
      //   userAgent: navigator.userAgent,
      //   error: `UNAUTHORIZED in response`,
      //   typeError: 'UNAUTHORIZED in response',
      //   routeHistory: store.getters.GET_ROUTER_HISTORY,
      // })
      //
      // let data = ErrorLogModel.prepareSave()
      // store.dispatch('createErrorLogs', data)

      // if this user is impersonated -> reload page, may new token has in localstorage
      if(store.getters.getUserProfile?.impersonated_user?.id) {
        location.reload()
        return
      }

      localStorage.removeItem('csrf');
      localStorage.removeItem('token');

      API.defaults.headers.common['X-CSRF-TOKEN'] = '';
      API.defaults.headers.common['Authorization'] = '';

      if(error.config.url !== 'access-controls/user-access/list'){
        router.push(routerPaths.auth)
      }
    }

    if(error.response.status === store.getters.GET_ERRORS.FORBIDDEN_CODE){
      router.push(routerPaths.notFound)
      console.log(store);
    }

    if(error.response.status === store.getters.GET_ERRORS.NOT_FOUND_CODE){
      router.push(routerPaths.notFound)
      console.log(store);
    }

    if(error.response.status === store.getters.GET_ERRORS.TECHNICAL_WORKS_CODE){
      store.commit('SET_TECHNICAL_WORKS_MODE', true)
    }

    if(
      error.response.status === store.getters.GET_ERRORS.SERVER_ERROR_CODE ||
      error.response.status === store.getters.GET_ERRORS.BAD_REQUEST_CODE ||
      error.response.status === store.getters.GET_ERRORS.BLOCKED_CODE
    ){
      /**
       * Save error in API
       */
      let ErrorLogModel = new ErrorLog()
      ErrorLogModel.setItem({
        baseURI: router.app?.$el.baseURI,
        vueComponent: 'Axios',
        user: `Id: ${store.getters.GET_COMMON_AUTHORIZED?.user?.id}, email: ${store.getters.GET_COMMON_AUTHORIZED?.user?.email}`,
        userAgent: navigator.userAgent,
        error: `Status: ${error.response.status}; Response data: ${JSON.stringify(error.response.data)}; Response headers: ${JSON.stringify(error.response.headers)}`,
        typeError: 'Axios response error;',
        routeHistory: store.getters.GET_ROUTER_HISTORY,
      })

      let data = ErrorLogModel.prepareSave()
      store.dispatch('createErrorLogs', data)
    }

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }
  console.log(error.config);

  return Promise.reject(error);
});

