import {routerAdminPaths} from './routerAdminPaths.js'
import {ADMIN_ROUTE_STORAGE, RETURN_GOODS_TYPES} from "../staticData/staticVariables";

export const routerPathsMain = {
  auth: '/auth',
  login: '/auth/login',
  forgot: '/auth/forgot',
  forgotReset: '/auth/forgot-reset',

  getFile: '/file',
  getPdfFile: '/file/pdf',
  getPdfFileId: '/file/pdf/:id',
  generatePdfFile: '/generate/pdf',
  generatePdfFileId: '/generate/pdf/:type',

  authAdmin: '/admin/auth',
  loginAdmin: '/admin/auth/login',
  forgotAdmin: '/admin/auth/forgot',
  forgotResetAdmin: '/admin/auth/forgot-reset',

  signUp: '/registration',

  authentication: '/profile',
  authenticationGoogle: '/profile/google',
  authenticationEmail: '/profile/email',
  authenticationGoogleType: '/profile/google/:linkName',
  authenticationEmailType: '/profile/email/:linkName',

  transactions: '/transactions',
  cusPayments: '/transactions/customer-payments',
  cusPaymentsEdit: '/transactions/customer-payments/edit',
  financeMyPayments: '/finance/my-payments',

  financeUnidentifiedPayments: '/finance/unidentified-payments',

  financeUkrTreasuresLink: '/finance/ukrainian-treasures',
  financeUkrTreasuresLinkCreate: '/finance/ukrainian-treasures/create',
  financeUkrTreasuresLinkEdit: '/finance/ukrainian-treasures/:id',

  financeUkrTreasuresPayPalButtonCreate: '/finance/create-paypal-button',

  financeRequestReturn: '/finance/request-return',
  financeRequestReturnEdit: '/finance/request-return/edit',
  financeRequestReturnEditId: '/finance/request-return/edit/:id',
  financeRequestReturnCreate: '/finance/request-return/create',
  financeRequestReturnCreateId: '/finance/request-return/create/:id',

  financePayPalCases: '/finance/paypal-cases',
  financePayPalCasesCreate: '/finance/paypal-cases/create',
  financePayPalCasesEdit: '/finance/paypal-cases/:id',
  financePayPalCasesShow: '/finance/paypal-cases/show/:id',

  financeInvoices: '/finance/invoices',
  financeInvoicesCreate: '/504',
  financeInvoicesEdit: '/finance/invoices/edit/:id',

  financeRequestFunds: '/finance/request-funds',
  financeRequestFundsIndividual: '/finance/request-funds/individual',
  financeRequestFundsBusiness: '/finance/request-funds/business',
  financeRequestFundsIndividualEdit: '/finance/request-funds/individual/:id',
  financeRequestFundsBusinessEdit: '/finance/request-funds/business/:id',

  financeNewPayment: '/finance/new-payment',
  financeNewPaymentCreate: '/finance/new-payment/create',
  financeNewPaymentEdit: '/finance/new-payment/edit/:id',

  financeNewPaymentsPayoneer: '/finance/new-payoneer-payment',
  financeNewPaymentsPayoneerCreate: '/finance/new-payoneer-payment/create',
  financeNewPaymentsPayoneerEdit: '/finance/new-payoneer-payment/edit/:id',

  financeReportCells: '/504',
  financeReportCellsItem: '/504',

  scanFormDP: '/504',

  fedexUkraineReport: '/504',
  fedexUkraineReportShow: '/504',

  forbiddenGoods: '/finance/forbidden-goods',

  forbiddenKeywords: '/noPage',

  shippingInsurances: '/noPage',

  consolidationUsers: '/noPage',

  trackTest: '/504',

  problemsFillClaim: '/problems/fill-claim',
  problemsFillClaimCreate: '/problems/fill-claim/create',
  problemsFillClaimEdit: '/problems/fill-claim/:id',
  problemsFillClaimShow: '/problems/fill-claim/show/:id',

  problemsParcels: '/problems/parcels',

  problemsReturnsModule: '/admin/problems',
  problemsUnidentifiedReturns: '/problems/unidentified-returns',
  problemsMyReturns: '/problems/my-returns',
  problemsProcessedReturnsGoods: '/problems/processed-package-returns',
  problemsProcessedReturnsGoodsType: '/504',
  problemsProcessedReturnsGoodsMyReturns: '/problems/processed-package-returns/' + RETURN_GOODS_TYPES.MY_RETURNS,
  problemsProcessedReturnsGoodsUnprocessed: '/504',
  problemsProcessedReturnsGoodsProcessed: '/504',
  problemsProcessedReturnsGoodsTrash: '/504',
  problemsRequestReturnsGoods: '/504',
  problemsReturnGoodsUrl: '/problems/return-package',
  problemsReturnGoodsCreation: '/problems/return-package/create',
  problemsReturnGoodsEditing: '/504',
  problemsReturnGoodsShow: '/problems/return-package/show/:id',

  // problemsMeestReturns: '/problems/return-meest',
  // problemsMeestReturnsEdit: '/problems/return-meest/:id',
  // problemsMeestReturnsShow: '/problems/return-meest/show/:id',

  ordersAndDispatches: '/orders-and-dispatches',


  incomeExpenses: '/income-and-expenses/',
  incomeExpensesProfitReport: '/income-and-expenses/profit-report',
  incomeExpensesShippingReport: '/income-and-expenses/shipping-report',

  expenses: '/504',
  incomeExpensesNewExpense: '/income-and-expenses/expenses/create',
  incomeExpensesEdit: '/income-and-expenses/expenses/edit',
  incomeExpensesEditItem: '/income-and-expenses/expenses/edit/:id',

  otherIncome: '/504',
  incomeOtherIncomeCreate: '/income-and-expenses/other-income/create',
  incomeOtherIncomeEdit: '/income-and-expenses/other-income/edit',
  incomeOtherIncomeEditItem: '/income-and-expenses/other-income/edit/:id',



  expressOrders: '/orders-and-dispatches/express',
  expressOrderCreation: '/orders-and-dispatches/express/create/',
  expressOrderCreationID: '/orders-and-dispatches/express/create/:type',
  expressOrderCreationIDTransaction: '/orders-and-dispatches/express/create/:type/:typeTransaction/:id',
  expressOrderEdit: '/orders-and-dispatches/express/edit',
  expressOrderEditItem: '/orders-and-dispatches/express/edit/:type/:id',
  expressOrderShow: '/orders-and-dispatches/express/show',
  expressOrderShowItem: '/orders-and-dispatches/express/show/:type/:id',
  expressLabels: '/orders-and-dispatches/express/labels',


  easyOrders: '/orders-and-dispatches/easy',
  easyOrderCreation: '/orders-and-dispatches/easy/create/',
  easyOrderCreationID: '/orders-and-dispatches/easy/create/:type',
  easyOrderCreationIDTransaction: '/orders-and-dispatches/easy/create/:type/:typeTransaction/:id',
  easyOrderEdit: '/orders-and-dispatches/easy/edit',
  easyOrderEditItem: '/orders-and-dispatches/easy/edit/:type/:id',
  easyOrderShow: '/orders-and-dispatches/easy/show',
  easyOrderShowItem: '/orders-and-dispatches/easy/show/:type/:id',
  easyLabels: '/orders-and-dispatches/easy/labels',


  ordersMeestReturns: '/orders-and-dispatches/meest',
  ordersMeestReturnsCreation: '/orders-and-dispatches/meest/create',
  ordersMeestReturnsEdit: '/orders-and-dispatches/meest/:id',
  ordersMeestReturnsShow: '/orders-and-dispatches/meest/show/:id',


  ordersFBM: '/orders-and-dispatches/orders',
  ordersFBMManifests: '/manifests',
  ordersFBMCreate: '/orders-and-dispatches/orders/create',
  ordersFBMCreation: '/orders-and-dispatches/orders/create/:type',
  ordersFBMCreationTransactionId: '/orders-and-dispatches/orders/create/:type/:typeTransaction/:id',
  ordersFBMItem: '/orders-and-dispatches/orders/show/:type/:id',
  // ordersFBMCreationConsolidation: '/orders-and-dispatches/orders/create/consolidation',
  // ordersFBMCreationWarehouse: '/orders-and-dispatches/orders/create/warehouse',
  // ordersFBMCreationLabel: '/orders-and-dispatches/orders/create/label',
  // ordersFBMCreationEasy: '/orders-and-dispatches/orders/create/easy',

  ordersFBMEditing: '/orders-and-dispatches/orders/:type/:id',
  ordersFBMShow: '/orders-and-dispatches/orders/show/:type/:id',
  ordersFBMEditingConsolidation: '/orders-and-dispatches/orders/edit/consolidation/',
  ordersFBMEditingWarehouse: '/orders-and-dispatches/orders/edit/warehouse/',
  // ordersFBMEditingLabel: '/orders-and-dispatches/orders/edit/label/:id',
  // ordersFBMEditingEasy: '/orders-and-dispatches/orders/edit/easy/:id',

  ordersFBA: '/orders-and-dispatches/fba-orders',
  ordersFBACreation: '/orders-and-dispatches/fba-orders/create/',
  ordersFBAEditing: '/orders-and-dispatches/fba-orders/:id',
  ordersFBAShow: '/orders-and-dispatches/fba-orders/show/:id',

  ordersShipmentWarehouse: '/orders-and-dispatches/shipment',
  ordersShipmentWarehouseCreate: '/orders-and-dispatches/shipment/create/',
  ordersShipmentWarehouseCreation: '/orders-and-dispatches/shipment/create/:type',
  ordersShipmentWarehouseEditing: '/orders-and-dispatches/shipment/:type/:id',
  ordersShipmentWarehouseDetail: '/orders-and-dispatches/shipment/detail/',
  ordersShipmentWarehouseDetailInfo: '/orders-and-dispatches/shipment/detail/:type/:id',

  ordersConsolidation: '/orders-and-dispatches/consolidation',
  ordersConsolidationCreate: '/orders-and-dispatches/consolidation/create',
  ordersConsolidationCreation: '/orders-and-dispatches/consolidation/create/:type',
  ordersConsolidationDetail: '/orders-and-dispatches/consolidation/detail',
  ordersConsolidationDetailInfo: '/orders-and-dispatches/consolidation/detail/:type/:id',
  ordersConsolidationEditing: '/orders-and-dispatches/consolidation/edit',
  ordersConsolidationEditingItem: '/orders-and-dispatches/consolidation/edit/:type/:id',

  ukrposhta: '/orders-and-dispatches/ukrposhta',
  ukrposhtaCreation: '/orders-and-dispatches/ukrposhta/create',
  ukrposhtaCreationPayments: '/orders-and-dispatches/ukrposhta/create/:typeTransaction/:id',
  ukrposhtaEditing: '/orders-and-dispatches/ukrposhta/:id',
  ukrposhtaShow: '/orders-and-dispatches/ukrposhta/show/:id',

  belposhta: '/orders-and-dispatches/belposhta',
  belposhtaCreation: '/orders-and-dispatches/belposhta/create',
  belposhtaCreationPayments: '/orders-and-dispatches/belposhta/create/:typeTransaction/:id',
  belposhtaEditing: '/orders-and-dispatches/belposhta/:id',
  belposhtaShow: '/orders-and-dispatches/belposhta/show/:id',

  cdek: '/orders-and-dispatches/cdek',
  cdekCreation: '/orders-and-dispatches/cdek/create',
  cdekCreationFromOrder: '/orders-and-dispatches/cdek/create/:type/:id',
  cdekEditing: '/orders-and-dispatches/cdek/:id',
  cdekShow: '/orders-and-dispatches/cdek/show/:id',

  novaPoshta: '/orders-and-dispatches/nova-poshta',
  novaPoshtaCreation: '/orders-and-dispatches/nova-poshta/create',
  novaPoshtaCreationFromOrder: '/orders-and-dispatches/nova-poshta/create/:type/:id',
  novaPoshtaEditing: '/orders-and-dispatches/nova-poshta/:id',
  novaPoshtaShow: '/orders-and-dispatches/nova-poshta/show/:id',

  importOrders: '/orders-and-dispatches/import-orders',

  warehouseDisposalOrders: '/warehouse/disposal-orders',
  warehouseDisposalOrdersCreation: '/warehouse/disposal-orders/create',
  warehouseDisposalOrdersEdit: '/warehouse/disposal-orders/:id',

  products: '/warehouse/products',
  productsCreate: '/warehouse/products-create',
  productsItem: '/warehouse/products/show/:id',
  productsEdit: '/warehouse/products/:id',
  productsImport: '/warehouse/products/import',

  refillProducts: '/504',


  marketplaceProducts: '/marketplace/marketplace-products',
  marketplaceProductsCreate: '/marketplace/marketplace-products/create',
  marketplaceProductsItem: '/marketplace/marketplace-products/show/:id',
  marketplaceProductsEdit: '/marketplace/marketplace-products/:shopId/:id',

  marketplaceRates: '/marketplace/marketplace-rates',


  marketplaceReviews: '/marketplace/marketplace-reviews',
  marketplaceReviewsCreate: '/marketplace/marketplace-reviews/create',
  marketplaceReviewsItem: '/marketplace/marketplace-reviews/show/:id',
  marketplaceReviewsEdit: '/marketplace/marketplace-reviews/:id',

  marketplaceSettingsEditing: '/shops/marketplace-settings',
  marketplaceSettingsEditItem: '/shops/marketplace-settings/:id',


  dashboard: '/dashboard',
  dashboardAdmin: '/admin/dashboard',
  dashboardUser: '/dashboard',


  calculator: '/shipping-calculator',

  calculatorEngraving: '/shipping-engraving-calculator',

  statisticsEtsyRank: '/statistics/etsy-rank',

  statisticsDeliveryStatistics: '/statistics/delivery-statistics',

  notificationsList: '/list-notification',

  graphicsStatistic: '/admin/statistics/graphics-statistic',

  reportsConsolidationMeest: '/504',
  reportsConsolidationMeestCreation: '/504',
  reportsConsolidationMeestEdit: '/504',
  reportsConsolidationMeestShow: '/504',
  reportsConsolidationMeestSent: '/504',
  reportsConsolidationMeestSentShow: '/504',

  reportsAPCDelcon: '/504',
  reportsAPCDelconCreate: '/504',
  reportsAPCDelconEdit: '/504',
  reportsAPCDelconEditItem: '/504',

  reportsConsolidation: '/reports/consolidation-union',
  reportsConsolidationCreate: '/reports/consolidation-union/create',
  reportsConsolidationEdit: '/reports/consolidation-union/edit',
  reportsConsolidationEditItem: '/reports/consolidation-union/edit/:id',
  reportsConsolidationCreateLabel: '/504',
  reportsConsolidationCreateLabelItem: '/504',
  reportsConsolidationEditLabel: '/504',
  reportsConsolidationEditLabelItem: '/504',

  warehouseProductsReport: '/warehouse/product-report',

  productsMove: '/504',

  reportsProfitReport: '/504',
  reportsProfitReportTotal: '/504',
  reportsProfitReportTransactions: '/504',

  reportsGenerateReportPage: '/504',
  reportsGenerateReportCurOrdersPage: '/504',

  reportsGenerateReportFBAPage: '/504',

  reportsProcessFBMReport: '/504',
  trackingForPayPal: '/504',

  reportsConsolidationOrders: '/504',

  reportsConnectionsHistory: '/reports/connection-history',

  expressPoshtaReport: '/504',

  IE559Report: '/finance/ie-599',

  reportsFastOrders: '/504',
  reportsFastOrdersCreate: '/504',
  reportsFastOrdersEdit: '/504',
  reportsFastOrdersEditItem: '/504',

  reportsExpressFastOrders: '/504',
  reportsExpressFastOrdersCreate: '/504',
  reportsExpressFastOrdersEdit: '/504',
  reportsExpressFastOrdersEditItem: '/504',

  reportsExpressProblem: '/504',

  transactionReports: '/504',

  shippingReport: '/504',

  transactionExport: '/504',

  inactiveFBMUsers: '/504',

  settings: '/system/',
  settingsUsers: '/system/users',
  settingsUsersItem: '/system/users/:id',

  settingsRoles: '/system/roles',
  settingsRolesCreate: '/system/roles-create',
  settingsRolesItem: '/system/roles/:id',

  settingsPermissions: '/system/permissions',
  settingsPermissionsCreate: '/system/permissions-create',
  settingsPermissionsItem: '/system/permissions/:id',
  settingsPermissionsGroup: '/system/group-permissions',
  settingsPermissionsGroupCreate: '/system/group-permissions-create',
  settingsPermissionsGroupItem: '/system/group-permissions/:id',

  settingsTranslations: '/settings/translations',
  settingsTranslationsCreate: '/settings/translations-create',
  settingsTranslationsItem: '/settings/translations/:id',
  settingsTranslationsGroup: '/system/group-translations',
  settingsTranslationsGroupCreate: '/system/group-translations-create',
  settingsTranslationsGroupItem: '/system/group-translations/:id',

  settingsVueComponents: '/system/vue-components',
  settingsVueComponentsCreate: '/system/vue-components-create',
  settingsVueComponentsItem: '/system/vue-components/:id',

  settingsAdminAuth: '/settings/admin-auth',


  mainSettings: '/settings',

  mainSettingsEdit: '/settings-edit',

  mainSettingsMyProfile: '/settings/profile',

  mainSettingsCustomsInformation: '/settings/customs-information',

  mainSettingsNotifications: '/settings/notifications',

  mainSettingsShops: '/settings/shops',
  mainSettingsShopsCreate: '/settings/shops/create',
  mainSettingsShopsEdit: '/settings/shops/:id',

  downloadPlugins: '/settings/modules',

  mainSettingsWarehouseManagement: '/settings/warehouses-management',
  mainSettingsWarehouseManagementCreate: '/settings/warehouses-management/create',
  mainSettingsWarehouseManagementEdit: '/settings/warehouses-management/:id',

  mainSettingsManageUsersLink: '/settings/subusers',
  mainSettingsManageUsersLinkCreate: '/settings/subusers/create',
  mainSettingsManageUsersLinkEdit: '/settings/subusers/:id',

  mainSettingsManageUsersGroupLink: '/settings/subusers/group',
  mainSettingsManageUsersGroupLinkCreate: '/settings/subusers/group/create',
  mainSettingsManageUsersGroupLinkEdit: '/settings/subusers/group/:id',

  balanceHistory: '/balance-history',

  balancePayoneerHistory: '/balance-history-payoneer',

  mainSettingsUsers: '/users',
  mainSettingsUsersCountCorrect: '/users/count-correct',
  mainSettingsUsersEdit: '/users/:id',
  mainSettingsUsersShow: '/users/show/:id',
  mainSettingsUsersSubAdminCreate: '/users/sub-admin-create',

  mainSettingsFreshChat: '/504',


  mainSettingsEtsyListings: '/settings/etsy-listings',
  mainSettingsEtsyListingsCreate: '/settings/etsy-listings/create',
  mainSettingsEtsyListingsCreateListing: '/settings/etsy-listings/:shopId/create',
  mainSettingsEtsyListingsEdit: '/settings/etsy-listings/new',
  mainSettingsEtsyListingsEditListing: '/settings/etsy-listings/:shopId/:listingId',
  mainSettingsEtsyListingsShop: '/settings/etsy-listings/:shopId',


  mainSettingsEtsyTransfer: '/settings/etsy-transfer',
  mainSettingsEtsyTransferShop: '/settings/etsy-transfer/shop/:id',


  mainSettingsProfilesLink: '/settings/user-templates',

  mainSettingsProformCategory: '/504',
  mainSettingsProformData: '/504',

  mainSettingsHSCodes: '/504',

  mainSettingsOtherShippers: '/settings/other-shippers',

  mainSettingsOrderPrice: '/504',
  mainSettingsZonePrice: '/504',
  mainSettingsOrderSize: '/504',
  mainSettingsPromoCode: '/504',

  mainSettingsEasypostInvoices: '/504',

  mainSettingsShippingCost: '/settings/shipping-cost',

  mainSettingsPackagingTypes: '/settings/packaging-types',
  mainSettingsPackagingTypesCreate: '/settings/packaging-types/create',
  mainSettingsPackagingTypesEdit: '/settings/packaging-types/:id',

  mainSettingsCustomizationOfferCreate: '/504',
  mainSettingsCustomizationOfferEdit: '/504',
  mainSettingsCustomizationOfferCreateType: '/504',
  mainSettingsCustomizationOfferEditType: '/504',

  mainSettingsCells: '/settings/cells',

  settingsOrderBin: '/settings/order-bin',

  mainSettingsInventory: '/settings/inventory',
  mainSettingsInventoryShow: '/settings/inventory/show/:id',
  mainSettingsInventoryEdit: '/504',

  mainSettingsIOSSNumbers: '/settings/ioss-numbers',
  mainSettingsIOSSNumbersCreate: '/settings/ioss-numbers/create',
  mainSettingsIOSSNumbersEdit: '/settings/ioss-numbers/edit/:id',
  mainSettingsIOSSNumbersShow: '/settings/ioss-numbers/show/:id',

  mainSettingsInsuranceSkladUsa: '/504',
  mainSettingsInsuranceSkladUsaCreate: '/504',
  mainSettingsInsuranceSkladUsaEdit: '/504',
  mainSettingsInsuranceSkladUsaShow: '/504',

  mainSettingsCalculatorSettings: '/504',

  thermalPrint: '/504',

  authTokens: '/504',
  scanBarcodeFBM: '/504',


  shippingCompany: '/settings/shipping-company',

  accountTypes: '/settings/account-banks',
  accountTypesCreate: '/settings/account-banks/create',
  accountTypesEditing: '/settings/account-banks/:id',


  accounts: '/settings/accounts',
  accountsCreate: '/settings/accounts/create/',
  accountsCreation: '/settings/accounts/create/:type',
  accountsEditing: '/settings/accounts/:type/:id',

  multiSearch: '/multi-search',
  multiSearchQuery: '/multi-search/:query',

  search: '/search',
  searchTrack: '/search/tracking-number',

  scanBarcode: '/504',

  synchronizeShop: '/synchronize/shop',

  shopifyConnection: '/shopify/app',

  shopifyInfoPage: '/shopify/connection/info',

  historyOfChanges: '/history',

  chat: '/sms-chat',

  heartysan: '/heartysan',
  heartysanChoice: '/heartysan/choice',

  heartysanManufacturerListings: '/heartysan/manufacturer-products',
  heartysanManufacturerListingsShow: '/heartysan/manufacturer-products/:id',

  heartysanSellerListings: '/heartysan/seller-listings',
  heartysanSellerListingsShow: '/heartysan/seller-listings/:id',

  heartysanListings: '/heartysan/listings',
  heartysanListingsCreate: '/heartysan/listings/create',
  heartysanListingsEdit: '/heartysan/listings/edit/:id',

  heartysanChat: '/heartysan/chat',

  heartysanCategory: '/504',
  heartysanCategoryCreate: '/504',
  heartysanCategoryEdit: '/504',
  heartysanCategoryShow: '/504',

  heartysanUsers: '/504',

  heartysanEtsyListings: '/heartysan/etsy-listings',
  heartysanEtsyListingsCreate: '/heartysan/etsy-listings/create',
  heartysanEtsyListingsCreateListing: '/heartysan/etsy-listings/:shopId/create',
  heartysanEtsyListingsEdit: '/heartysan/etsy-listings/new',
  heartysanEtsyListingsEditListing: '/heartysan/etsy-listings/:shopId/:listingId',
  heartysanEtsyListingsShop: '/heartysan/etsy-listings/:shopId',

  exportRequests: '/504',

  errorLogs: '/504',
  errorLogsShow: '/504',

  errorLogsBackend: '/504',
  errorLogsBackendShow: '/504',

  negativeBalance: '/504',

  errorPage503: '/503',
  errorPage504: '/504',
  errorPage404: '*',
  notFound: '/404',
}


let adminRouteStorage = localStorage.getItem(ADMIN_ROUTE_STORAGE)

export const routerPaths = (adminRouteStorage !== null) ? routerAdminPaths : routerPathsMain
// export const routerPaths =  routerPathsMain
//   () => {
//   alert(123)
//   console.log(routerAdminPaths)
//   return {routerPathsMain: routerPaths}
// }
