
import {routerPaths} from '../../../router/routerPaths'
// import permissions from "../../../router/middleware/permissions";
// import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ProductsMoveTable = () => import(/* webpackChunkName: "group-products-move" */ './components/ProductsMoveTable/ProductsMoveTable.vue')
const moduleKey = 'products-move'


export const ProductsMoveRoutes = [
  {
    path: routerPaths.productsMove,
    component: ProductsMoveTable,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'products'
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.productsMove,
          },
          'products': {
            name: 'menu_productsMove',
            link: routerPaths.productsMove,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.FORBIDDEN_PAYMENT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_productsMove'
    },
  }
]
