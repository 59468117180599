<template>
  <div id="app" v-bind:class="{'translation-mode': this.$store.getters.GET_TRANSLATION_MODE}">

    <template v-if="!$store.getters.GET_TECHNICAL_WORKS_MODE">

      <template v-if="checkAuth && checkTranslation">
        <AuthLayout v-if="layout === 'auth'" />

        <FileLayout v-if="layout === 'file'" />

        <NotAuthLayout v-if="layout === 'not-auth'" />

        <DefaultLayout v-if="layout === 'default'" />

        <TranslationPopup v-if="$store.getters.getOpenTranslationPopup"/>


        <NotificationFixed/>

        <DetailNotificationPopup
            v-if="$store.getters.getOpenNotificationPopup"
            :notificationId="notificationId"
        />

        <DeleteItemPopup
            @close="cancelDeletePopup"
            @confirm="(comment) => confirmDeletePopup(comment)"
            v-if="$store.getters.getConfirmDeletePopup"
            :textConfirmDeletePopup="textConfirmDeletePopup"
            :confirmDeletePopupComment="confirmDeletePopupComment"
        />


        <SaveInDraftsPopup
            v-if="$store.getters.getSaveInDraftsPopup"
            @close="cancelDraftsPopup"
            @yes="confirmDraftsPopup"
        />

        <HistoryOfChangesPopup
            v-if="$store.getters.getHistoryOfChangesPopup"
            :type="historyOfChangeType"
            :uuid="historyOfChangeUUID"
            @close="cancelHistoryOfChangesPopup"
        />

      </template>

    </template>

    <TechnicalWorksModule
            v-if="$store.getters.GET_TECHNICAL_WORKS_MODE"
    />

    <FreshChat/>

    <SiteTitleManager/>

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Vue from 'vue'

import AuthLayout from './components/layouts/AuthLayout/AuthLayout.vue'
import DefaultLayout from './components/layouts/DefaultLayout/DefaultLayout.vue'

import {routerPaths} from './router/routerPaths'
import TranslationPopup from "./components/coreComponents/Popups/TranslationPopup/TranslationPopup";

import {getLocalTranslations, loadLanguageAsync} from './language/language'
import NotificationFixed from "./components/coreComponents/NotificationFixed/NotificationFixed";
import DetailNotificationPopup
  from "./components/modules/NotificationModule/popups/DetailNotificationPopup/DetailNotificationPopup";
import DeleteItemPopup from "./components/coreComponents/Popups/DeleteItemPopup/DeleteItemPopup";
import TechnicalWorksModule from "./components/modules/NotFoundModule/TechnicalWorksModule";
import {userDataMixin} from "./mixins/globalMixins/userDataMixin";
import SaveInDraftsPopup from "./components/coreComponents/Popups/SaveInDraftsPopup/SaveInDraftsPopup";
import {dataOperationsMixin} from "./mixins/globalMixins/dataOperationsMixin";
import {userSettingsMixin} from "./mixins/globalMixins/userSettingsMixin";
import {globalHelperMixin} from "./mixins/globalMixins/globalHelperMixin";
import FileLayout from "./components/layouts/FileLayout/FileLayout";
import {globalAPIFunctionsMixin} from "./mixins/globalMixins/globalAPIFunctionsMixin";
import {globalUserPermissionMixin} from "./mixins/globalMixins/globalUserPermissionMixin";
import {siteSettingsMixin} from "./mixins/globalMixins/siteSettingsMixin";
import FreshChat from "@/components/coreComponents/FreshChat/FreshChat";
import SiteTitleManager from "@/components/coreComponents/SiteTitleManager/SiteTitleManager";
import HistoryOfChangesPopup from "@/components/coreComponents/Popups/HistoryOfChangesPopup/HistoryOfChangesPopup";
import {exportRequestsSocketMixin, heartysanChatSocketMixin} from "@/mixins/socketMixins/socketMixin";
import {globalErrorLogsMixin} from "@/mixins/globalMixins/globalLogErrorsMixin";
import NotAuthLayout from "@/components/layouts/NotAuthLayout/NotAuthLayout";
import {ErrorLog} from "@/components/globalModels/ErrorLog";

// import {SET_AUTORIZE_DATA_AND_AVATAR} from "./services/commonFunctions";


Vue.mixin(userDataMixin);
Vue.mixin(dataOperationsMixin);
Vue.mixin(userSettingsMixin);
Vue.mixin(globalHelperMixin);
Vue.mixin(globalAPIFunctionsMixin);
Vue.mixin(globalUserPermissionMixin);
Vue.mixin(siteSettingsMixin);
Vue.mixin(globalErrorLogsMixin);


export default {
  name: 'App',

  components: {
    NotAuthLayout,
    HistoryOfChangesPopup,
    SiteTitleManager,
    FreshChat,
    FileLayout,
    SaveInDraftsPopup,
    TechnicalWorksModule,
    AuthLayout,
    DefaultLayout,
    TranslationPopup,
    NotificationFixed,
    DetailNotificationPopup,
    DeleteItemPopup
  },

  mixins: [heartysanChatSocketMixin, exportRequestsSocketMixin],


  data(){
    return{
      data: [],
      routerPaths: routerPaths,
      layout: false,

      notificationId: '',

      checkAuth: false,
      checkTranslation: false,

      technicalWorks: false,
    }
  },

  watch: {
    '$route' () {
      this.watchRouter()
    },

    loadUserRoleAndAuthorizedData() {
      this.createHeartisanChatSocket()
      this.initExportRequestsSocket()
    },
  },

  created() {

    this.$store.dispatch('checkAuth')

    // fix 22.08.2022 don't try to get permissions if no auth token
    if(!this.$store.getters.getLoadingUserRights && !(this.$store.getters.getToken === false || this.$store.getters.getToken.length === 0)){
      this.$store.dispatch('fetchCurrentUserRights').then(() => {
        console.log('default layout getCurrentUserRights' + this.$store.getters.getCurrentUserRights);
      })
    }

    this.checkExpiredOauthTokenFunc()


    // this.sockets.subscribe('examplee', (data) => {
    //   console.log(data);
    // });

    // console.log(Echo);


    //
    // window.Echo.channel('test')
    //     .listen('.test', (e) => {
    //       console.log(e)
    //     }).error(e => {
    //   console.log(e);
    // })



    // setTimeout(() => {
    //   window.Echo.disconnect();
    //   console.log('discon');
    // }, 10000)

    // this.sockets.unsubscribe('examplee');


    let localTranslation = localStorage.getItem('translation')
    if(localTranslation === null) {
      let interval = setInterval(() => {
        if(localStorage.getItem('translation') !== null) {
          this.checkTranslation = true
          clearInterval(interval)
        }
      },50)
    } else {
      this.checkTranslation = true
    }
  },

  mounted() {

    this.initGlobalConfirmDeletePopup()

    this.initGlobalSaveInDraftsPopup()

    this.initGlobalHistoryOfChangesPopup()

    this.initGlobalTranslatePopup()

    this.initGlobalNotificationPopup()

    this.initGlobalChangeLangFunc()

    this.initGlobalNotyfyPopup()

    this.initCheckImpersonate()

    this.initSitePaths()

    this.watchRouter()

    getLocalTranslations('en')

    this.initCommonData()

    this.initCachedAvatar()

    this.initDateMomentLang(this.$store.getters.GET_LANG)

    this.initGlobalOpenPdfBase64()

    this.initGlobalDownloadBase64File()

    // this.initGlobalSetPriceValue()


    this.createHeartisanChatSocket()

    this.initExportRequestsSocket()


    // SET_AUTORIZE_DATA_AND_AVATAR(this.$store)


    // console.log(this.$store.getters.getLang);
    // this.$store.commit('setLang', 'ru')
    // console.log(this.$store.getters.getLang);

    // console.log(this._i18n.locale = 'he');
    // alert(3423);

  //
  //   console.log(this.$route.meta.hasOwnProperty('layout'));
  //   console.log(Object.prototype.hasOwnProperty.call(this.$route.meta, 'layout'));


    // this.$store.dispatch('checkAuth').then((checkAuth) => {
    //   if(!checkAuth) this.$router.push('auth');
    // })

    // console.log(this.$router);
    // .push('asfdsd')

    // fetch('https://jsonplaceholder.typicode.com/todos/1')
    //   .then(response => response.json())
    //   .then(json => {
    //     console.log(json)
    //     this.data.push(json)
    //   })
  },

  methods: {

    createHeartisanChatSocket() {
      if(!this.loadUserRoleAndAuthorizedData) return
      if(!this.getCurrentUser?.hearty_user_type?.id) return

      let prepareSocket = this.prepareCreatePrivateSocketChannel('HeartysanChat')
      if(prepareSocket.active) {
        this.$store.commit('setHeartysanChatSocket', true)
        this.createHeartysanChatSocket(
          prepareSocket,
        (response) => {
          this.$store.commit('setHeartysanChatEvents', response)
          this.openNotify('chat', {chatLink: this.$store.getters.GET_PATHS.heartysanChat + '?chatId=' + response.data?.chat?.id + '&socketChat=1'})
        })
      }
    },

    initExportRequestsSocket() {
      if(!this.loadUserRoleAndAuthorizedData) return

      /**
       * Create Socket Channel
       * @type {*|{channel, active, event, user}|{active}}
       */
      let prepareSocket = this.prepareCreatePrivateSocketChannel('ExportRequests')
      console.log(444222333);
      console.log(prepareSocket);
      if(prepareSocket.active) {
        this.$store.commit('setExportRequestsSocket', true)
        this.createExportRequestsSocket(
          prepareSocket,
        (response) => {
          console.log(333333);
          console.log(response.data);
          if(response.data?.export_id && response.data?.status === 'success') {
            this.openNotify('exportFile', {
              downloadFile: () => {
                this.$store.dispatch('downloadFileFromServer', response.data.file.id).then(responseFile => {
                  console.log(22222);
                  console.log(response);
                  this.createDownloadLink({data: responseFile.data, name: `${this.$t('exportsInTable_fileName.localization_value.value')}.${response.data.file.extension}`, type: response.data.file.extension})
                })
              }
            }, 6000)
          }
        })
      }
    },

    createDownloadLink({data, name, type}){
      const blob = new Blob([data], {type: `application/${type}`})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)
    },

    // initGlobalSetPriceValue() {
    //   Vue.prototype.setPriceValue = (value) => {
    //     if(('' + value).indexOf('.') > -1){
    //       return value
    //     }
    //     return value + '.00'
    //   }
    // },

    initGlobalConfirmDeletePopup() {
      Vue.prototype.deleteFunc = (text, funcYes, funcNo = false, comment = false) => {
        this.$store.commit('setConfirmDeletePopup', true)
        this.confirmDeletePopup = funcYes
        this.confirmDeletePopupComment = comment
        this.textConfirmDeletePopup = text
        if(funcNo){
          this.cancelDeletePopup = funcNo
        } else {
          this.cancelDeletePopup = () => {
            this.$store.commit('setConfirmDeletePopup', false)
          }
        }
      }
    },

    initGlobalSaveInDraftsPopup() {
      Vue.prototype.saveInDraftsFunc = (funcYes, funcNo = false) => {
        this.$store.commit('setSaveInDraftsPopup', true)
        this.confirmDraftsPopup = funcYes
        if(funcNo){
          this.cancelDraftsPopup = funcNo
        } else {
          this.cancelDraftsPopup = () => {
            this.$store.commit('setSaveInDraftsPopup', false)
          }
        }
      }
    },

    initGlobalHistoryOfChangesPopup() {
      Vue.prototype.historyOfChangesFunc = (type, uuid) => {
        this.$store.commit('setHistoryOfChangesPopup', true)
        this.historyOfChangeType = type
        this.historyOfChangeUUID = uuid
        this.cancelHistoryOfChangesPopup = () => {
          this.$store.commit('setHistoryOfChangesPopup', false)
        }
      }
    },

    initCheckImpersonate() {
      Vue.prototype.checkImpersonate = () => {
        return localStorage.getItem('parentUserId') !== null
      }
    },

    initGlobalOpenPdfBase64() {
      Vue.prototype.globalOpenPdfBase64 = (base64) => {
        let byteCharacters = atob(base64);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let file = new Blob([byteArray], { type: 'application/pdf;base64' });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        // console.log('data:application/pdf;base64,' + encodeURI(base64));
        // let pdfWindow = window.open('data:application/pdf;base64,' + encodeURI(base64))
        // console.log(pdfWindow);
        // console.log(base64);
        // pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64) + "'></iframe>")
      }
    },

    initGlobalDownloadBase64File() {
      Vue.prototype.globalDownloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    initGlobalTranslatePopup() {
      Vue.prototype.editTranslate = (key) => {
        this.$store.dispatch('getTranslationsForKey', key)
      }
    },

    initGlobalNotificationPopup() {
      Vue.prototype.notificationPopup = (notificationId) => {
        this.$store.dispatch('getNotification', notificationId).then(() => {

          let notifications = this.$store.getters.getNotifications
          let notificationsNoRead = this.$store.getters.getNotificationsNoRead

          notificationsNoRead.map((item, index) => {
            if(item.id === notificationId) {
              notificationsNoRead.splice(index, 1)
              this.$store.commit('setNotificationsNoRead', {notificationsNoRead: notificationsNoRead})
            }
          })

          notifications.map((item, index) => {
            if(item.id === notificationId) {
              notifications[index].isViewed = true
              this.$store.commit('setNotifications', {notifications: notifications})
            }
          })

          this.$store.commit('setNotificationsCount', parseInt(this.$store.getters.getNotificationsCount) - 1)
          this.$store.commit('setOpenNotificationPopup', true)
        }).catch(error => this.createErrorLog(error))
      }
    },

    initGlobalChangeLangFunc() {
      Vue.prototype.changeLang = (item) => {
        loadLanguageAsync(item.locale).then(() => {
          this.$store.commit('SET_LANG', item.locale)
          //check impersonate and if user is logged in
          if (localStorage.getItem('parentUserId') === null && this.loadUserAuthorizedData !== false) {
            this.$store.dispatch('changeServerLocale', item.id)
          }
        })
        this.initDateMomentLang(item.locale)
      }
    },

    initGlobalNotyfyPopup() {
      /**
       * @param type
       * @param msg (may set without translation mode msg:{txtServer: 'random massage'})
       * @param duration
       */
      Vue.prototype.openNotify = (type, msg, duration = false) => {
        let notifyDuration
        if(!duration) {
          notifyDuration = type === 'error' ? 6000 : 3000
        } else {
          notifyDuration = duration
        }

        this.$notify({
          group: 'common',
          type: type,
          text: msg,
          duration: notifyDuration
        })
      }
    },


    initSitePaths() {
      this.$store.commit('SET_PATHS', this.routerPaths);
    },

    initCommonData() {
      this.$store.dispatch('fetchCommonData')
    },

    initCachedAvatar() {
      if(localStorage.getItem('avatar') !== null) {
        this.$store.commit('setAvatarSmall', localStorage.getItem('avatar'))
      }
    },

    initDateMomentLang(lang){
      if(lang === 'ua'){
        this.$moment.locale('uk')
      } else {
        this.$moment.locale(lang)
      }
    },

    watchRouter(){
      let checkRouter = setInterval(()=>{
        if(this.$route.matched.length > 0){
          if(Object.prototype.hasOwnProperty.call(this.$route.matched[0].meta, 'layout')){
            this.layout = this.$route.matched[0].meta.layout.name;
          } else {
            this.layout =  'default';
          }
          clearInterval(checkRouter);
        }
      }, 10);
    },


    checkExpiredOauthTokenFunc() {
      if (this.$store.getters.getToken.length === 0 || this.$store.getters.getToken === false) {
        this.checkAuth = true
        return
      }

      this.$store.dispatch('checkExpiredOauthToken').then((response) => {
        if (response.status === true) {
          this.checkAuth = true
        } else {
          this.$router.push(this.$store.getters.GET_PATHS.login)
          this.$store.commit('setAuth',{auth: false, token: false})
          localStorage.removeItem('token')
          localStorage.removeItem('csrf')
          this.checkAuth = true

          /**
           * Loging UNAUTHORIZED_CODE
           */
          let ErrorLogModel = new ErrorLog()
          ErrorLogModel.setItem({
            baseURI: this.$router.app?.$el.baseURI,
            vueComponent: 'UNAUTHORIZED ExpiredOauth',
            user: `Id: ${this.$store.getters.GET_COMMON_AUTHORIZED?.user?.id}, email: ${this.$store.getters.GET_COMMON_AUTHORIZED?.user?.email}`,
            userAgent: navigator.userAgent,
            error: `UNAUTHORIZED ExpiredOauth`,
            typeError: 'UNAUTHORIZED ExpiredOauth',
            routeHistory: this.$store.getters.GET_ROUTER_HISTORY,
          })

          let data = ErrorLogModel.prepareSave()
          this.$store.dispatch('createErrorLogs', data)
        }
      }).catch(error => this.createErrorLog(error))
    },

  },


}
</script>

<style lang="scss">
  @import 'normalize-scss';
  @include normalize();
  @import '../node_modules/bootstrap-4-grid/scss/grid';
  @import "./scss/mixins/mixins.scss";
  @import "./scss/main";
</style>
