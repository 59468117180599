
export const userSettingsMixin = {

  computed: {
    /**
     * Return User Tnt All Countries from user setting
     * @returns {boolean}
     */

    getUserTntAllCountriesSetting() {
      if(this.isAdmin)
        return true

      let user = this.$store.getters.getUserProfile

      return user.user_setting?.tnt_all_countries === '1'
    },

    /**
     * Return User FBM Has Create Label from user setting
     * @returns {boolean}
     */
    getUserFBMCreateLabelSetting() {
      if(this.isAdmin)
        return true

      let user = this.$store.getters.getUserProfile

      return user.user_setting?.order_fbm_self_create_label === '1'
    },

    /**
     * Return User Autodetect Fee from user setting
     * @returns {boolean}
     */
    getUserFBMAutodetectFee() {
      // if(this.isAdmin)
      //   return true

      let user = this.$store.getters.getUserProfile

      return user.user_setting?.autodetect_fee === 1
    },

    /**
     * Return Can User Request ATM
     * @returns {boolean}
     */
    getUserRequestATM() {
      if(this.isAdmin)
        return true

      let user = this.$store.getters.getUserProfile

      return user.user_setting?.atm_output === '1'
    },

    /**
     * Return Has User kit group permission
     * @returns {boolean}
     */
    getUserAccessKitGroup() {
      if(this.isAdmin)
        return true

      let user = this.$store.getters.getUserProfile

      return user.user_setting?.use_kit_group_bank === 1
    },

    /**
     * Return user fedex planned date
     */
    getUserPlannedDateFedex() {
      let user = this.$store.getters.getUserProfile

      return user.order_fbm_fedex_planned_date
    },

    /**
     * Return user planned date
     */
    getUserPlannedDate() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.planned_date
    },

    /**
     * Return user planned date
     */
    getUserPlannedDateWarehouse() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.order_fbm_warehouse_planned_date
    },

    /**
     * Return user planned date
     */
    getUserPlannedDateConsolidation() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.order_fbm_consolidation_planned_date
    },

    /**
     * Return user planned date
     */
    isUserPrepayment() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.consolidation_order_prepayment === 1
    },

    /**
     * Return user different template for orders
     */
    isExpertUser() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.express_order_template_type === 1
    },

    /**
     * Return user Order Process Fee
     */
    getUserOrderProcessFee() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.order_process_fee?.cost
    },
    getUserOrderProcessFeeObject() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.order_process_fee
    },

    /**
     * Return user Fedex No Transaction
     */
    getUserFedexNoTransaction() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.fedex_no_transaction === '1'
    },

    /**
     * Return user Use Payment Listing
     */
    getUserUsePaymentListing() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.use_payment_listing === 1
    },

    /**
     * Return user Use Payment Listing
     */
    getUserAccessWithoutGoogleAuth() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.allow_access_without_google_auth === 1
    },

    /**
     * Return user use i create label vipparcel
     */
    getUserICreateLabelUseVipparcel() {
      let user = this.$store.getters.getUserProfile

      return user.user_setting?.self_create_label_no_money
    },

  },

}
